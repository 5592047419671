<template>
  <v-container class="pt-0">
    <v-card :loading="loading" elevation="0">
      <div class="text-md-center" style="margin: 50px;" v-if="loading && delegations.length === 0">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </div>
      <v-row>

        <v-col cols="12">

          <v-expansion-panels class="elevation-1">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('erp.lang_extrasNote') }}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-tabs v-model="lang_tabs" v-if="this.isTranslationEnabled">
                  <v-tab>
                    <template v-slot:default>
                      <v-btn text class="ma-0">
                        <country-flag country="DE" />
                      </v-btn>
                    </template>
                  </v-tab>

                  <v-tab>
                    <template v-slot:default>
                      <v-btn text class="ma-0">
                        <country-flag country="GB" />
                      </v-btn>
                    </template>
                  </v-tab>

                  <v-tab>
                    <template v-slot:default>
                      <v-btn text class="ma-0">
                        <country-flag country="FR" />
                      </v-btn>
                    </template>
                  </v-tab>

                  <v-tab>
                    <template v-slot:default>
                      <v-btn text class="ma-0">
                        <country-flag country="SA" />
                      </v-btn>
                    </template>
                  </v-tab>


                  <v-tabs-items class="pt-3" v-model="lang_tabs">
                    <v-tab-item>
                      <v-row no-gutters align="center" justify="center">

                        <v-col cols="12" sm="8">
                          <v-textarea :value="this.value.ExtrasNote_de" @input="update('ExtrasNote_de', $event)"
                            @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('erp.lang_extrasNote')" autocomplete="off" outlined dense />
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- english tab -->
                    <v-tab-item>

                      <v-row no-gutters align="center" justify="center">

                        <v-col cols="12" sm="8">
                          <v-textarea :value="this.value.ExtrasNote_en" @input="update('ExtrasNote_en', $event)"
                            @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('erp.lang_extrasNote')" autocomplete="off" outlined dense />
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- french tab -->
                    <v-tab-item>
                      <v-row no-gutters align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-textarea :value="this.value.ExtrasNote_fr" @input="update('ExtrasNote_fr', $event)"
                            @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('erp.lang_extrasNote')" autocomplete="off" outlined dense />
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- arabic tab -->
                    <v-tab-item>
                      <v-row no-gutters align="center" justify="center">

                        <v-col cols="12" sm="8">
                          <v-textarea :value="this.value.ExtrasNote_ar" @input="update('ExtrasNote_ar', $event)"
                            @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('erp.lang_extrasNote')" autocomplete="off" outlined dense />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
                <v-row v-else no-gutters align="center" justify="center">

                  <v-col cols="12" sm="8">
                    <v-textarea v-model="this.value.ExtrasNote_de" @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('erp.lang_extrasNote')" autocomplete="off"
                      outlined dense />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="6">
          <v-checkbox :label="$t('erp.lang_disableGarnishQuery')" v-model="dontAskForExtras"
            :disabled="loadingDontAskForExtras" hide-details @click="updateDontAskForExtras" />
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn v-if="checkForChanges" @click="bulkSave" color="primary">
            {{ $t("generic.lang_saveChanges") }}
          </v-btn>
        </v-col>

        <v-divider class="ma-0" />
        <v-col cols="12" sm="6" lg="6" md="6" xl="6">
          <v-autocomplete v-model="searchName" :items="delegations" outlined chips small-chips multiple deletable-chips
            item-text="1" item-value="0" :label="$t('erp.lang_garnishName')"
            @change="getDelegations();searchGroup = [];" hide-details></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" lg="6" md="6" xl="6">
          <v-autocomplete v-model="searchGroup" :items="garnishGroups" outlined chips small-chips multiple
            deletable-chips item-text="name" item-value="uuid" :label="$t('erp.lang_garnishGroups')" hide-details
            @change="getDelegations();searchName = [];"></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider class="ma-0" />
      <v-data-table :headers="datatableHeaders" hide-default-header :loading="loading" :options.sync="pagination"
        :server-items-length="total" :footer-props="footerProps">
        <template v-slot:header="{ headers }">
          <thead>
            <tr>
              <template v-for="(header, index) in datatableHeaders">
                <th v-if="!header.hide" :key="header.text " class="text-center">
                  {{ header.text }}

                  <EditItemExtrasActivateButton v-if="index > 3 && searchGroup?.length>0" :garnish-tab="header"
                    :item-i-d="itemID" @activate-all="handleActivateAll" @deactivate-all="handleDeactivateAll"
                    @finish="getGarnishTabs"></EditItemExtrasActivateButton>
                </th>
              </template>

            </tr>
          </thead>
        </template>
        <template v-slot:body>
          <tbody>
            <tr :key="delegationIndex" v-for="(delegation, delegationIndex) in delegations">
              <template v-for="(header, index) in datatableHeaders">
                <td v-if="index > 3" :key="header.text " class="clickable-cell"
                  :style="{backgroundColor: (targetId === delegation[0]+'_'+index ? '#42f56680' : (delegation[header.value].active ? '#42f566' : ''))}"
                  @click="openEditMenu(delegation, header.value, $event)" :id="delegation[0]+'_'+index">
                  {{ delegation[header.value].price | currency }}
                </td>
                <td v-else-if="!header.hide" :key="header.text+'_'+index">
                  {{ delegation[header.value] }}
                </td>
              </template>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer.prepend>
          <v-btn @click="clearActive" :loading="loading" color="warning" text>
            {{ $t("erp.lang_clearActive") }}
          </v-btn>
        </template>
      </v-data-table>
      <v-divider class="ma-1" />
    </v-card>

    <!-- External v-menu for editing -->
    <v-menu v-model="menuOpen" :position-x="menuPosition.x" :position-y="menuPosition.y" absolute
      :close-on-content-click="false" :close-on-click="false">
      <v-card min-width="250">
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-checkbox :label="$t('settings.lang_settings_activ')" v-model="editActive" hide-details></v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-currency-field autofocus :label="$t('generic.lang_edit')" v-model="editPrice" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelEdit" color="warning">
            {{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-btn color="primary" text @click="saveEdit">
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import EditItemExtrasActivateButton from "@/components/erp/baseData/edititem/EditItemExtrasActivateButton";
import {createNamespacedHelpers} from "vuex";
import mixin from "../../../../mixins/KeyboardMixIns";
import CountryFlag from "vue-country-flag";
import Template from "@/components/settings/customerdisplay/Template";
import { debounce } from "lodash";

export default {
  name: "EditItemExtras",
  mixins: [mixin],
  components: {Template, EditItemExtrasActivateButton, CountryFlag},
  props: {
    itemID: Number,
    dontAskForExtrasProp: Boolean,
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      lang_tabs: 0,
      window: window,
      ExtrasNote_de: "",
      ExtrasNote_en: "",
      ExtrasNote_fr: "",
      ExtrasNote_ar: "",
      garnishTabs: [],
      delegations: [],
      loading: false,
      searchName: [],
      searchGroup: [],
      garnishGroups: [],
      dontAskForExtras: false,
      loadingDontAskForExtras: false,
      pagination: {},
      footerProps: {"items-per-page-options": [20, 40, 60, 100,-1]},
      total: 0,

      originalDelegations: null,
      price: null,
      targetId: null,
      active: false,
      isCanceled: false,
      menuOpen: false,
      menuPosition: { x: 0, y: 0 },
      editingDelegation: null,
      editingHeaderValue: null,
      editActive: false,
      editPrice: 0,
    }
  },

  watch: {
    pagination: {
      handler() {
        this.getDelegations();
      },
      deep: true,
    },
  },
  beforeMount() {

  },
  beforeCreate() {

  },
  mounted() {
    this.dontAskForExtras = this.dontAskForExtrasProp;
    this.getGarnishTabs();
    this.getGarnishGroups();

  },

  computed: {
    datatableHeaders() {
      let headers = [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: '0'
        },
        {
          text: 'Extra-Name',
          align: 'left',
          sortable: false,
          value: '1'
        },
        {
          text: 'uuid',
          align: 'left',
          sortable: false,
          value: '2',
          hide: true,
        },
        {
          text: 'Garnish Group',
          align: 'left',
          sortable: false,
          value: '3'
        },
      ];

      let counter = 4;

      this.garnishTabs.forEach((garnishTab) => {
        headers.push({
          text: garnishTab.name,
          sortable: false,
          value: counter.toString()
        });

        counter++;
      });

      return headers;
    },
    getDelegates() {
      return this.delegations
    },
    filteredData() {
      let filtered = [];

      if (this.searchName.length < 1 && this.searchGroup.length < 1) {
        filtered = this.delegations;
        return filtered;
      }


      filtered = this.getDelegates.filter(deleg => {
        if (this.searchName.length < 1 && this.searchGroup.length > 0) {
          return this.searchGroup.includes(deleg[2]);
        } else if (this.searchGroup.length < 1 && this.searchName.length > 0) {
          return this.searchName.includes(deleg[1]);
        } else {
          return this.searchName.includes(deleg[1]) || this.searchGroup.includes(deleg[2]);
        }
      })

      return filtered;
    },
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    },
    checkForChanges() {
      if (!this.originalDelegations?.length || !this.delegations?.length) return false;

      // Compare current with original
      const currentStr = JSON.stringify(this.delegations);

      return currentStr !== this.originalDelegations;
    }
  },

  methods: {
    update(key, value) {
      this.$emit('input', {...this.value, [key]: value})
    },
    getGarnishGroups() {
      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.garnishGroups = res.data.groups;
        }
      }).catch(err => {

      })
    },
    getGarnishTabs() {
      this.loading = true;
      this.garnishTabs = [];
      this.axios.post(ENDPOINTS.ERP.GARNISHTABS.GET).then((res) => {
        if (res.data.success) {
          this.garnishTabs = res.data.data;
          this.getDelegations();
        } else {
          Events.$emit("showSnackbar", {
            message: "Daten können nicht angezeigt werden",
            color: "error"
          });

          this.loading = false;
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: "Daten können nicht angezeigt werden",
          color: "error"
        });

        this.loading = false;
      });
    },
    loadData(){
      this.loading=true;
      this.axios.get(ENDPOINTS.DATATABLES.ERP.DELEGATEGARNISH, {
      params: {
        sEcho: 1,
        iDisplayStart:this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
        itemID: this.itemID,
        garnishGroupUUID:this.searchGroup,
        garnishID:this.searchName,
      }
      }).then((res) => {
      let delegations = [];
      this.originalDelegations = null;
      this.total = parseInt(res.data.iTotalDisplayRecords);
      res.data.aaData.forEach((row) => {
        let data = {};

        this.datatableHeaders.forEach((header) => {
        data[header.value] = row[header.value];
        });

        delegations.push(data);
      });
      this.delegations = delegations;
      this.originalDelegations = JSON.stringify(delegations);
      }).finally(()=>{
      this.loading = false;
      })
    },
    getDelegations: debounce(function() {
      if(this.checkForChanges){
        this.$swal({
        title: this.$t('generic.lang_warning'),
        text: this.$t('generic.lang_unsavedChangesWarning'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_saveChanges'),
        denyButtonText: this.$t('generic.lang_continueWithoutSaving'),
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.bulkSave();
        },
        preDeny: () => {
          this.loadData();
        },
        allowOutsideClick: () => false,
        });
        return;
      }
      this.loadData();
    }, 500),
    saveDelegation(extra, column, extraID) {
      this.loading = true;

      this.axios.post(ENDPOINTS.ERP.ITEM.DELEGATEGARNISH.UPDATE, {
        row_id: extraID,
        column: column,
        extraPrice: extra.price,
        waregroupID: 0,
        editWareID: this.itemID,
        value: extra.active
      }).then((res) => {
        this.loading = false;

        if (!res.data.success) {
          Events.$emit("showSnackbar", {
            message: "Daten konnten nicht gespeichert werden",
            color: "error"
          });
        }

        this.getGarnishTabs();
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: "Daten konnten nicht gespeichert werden",
          color: "error"
        });

        this.getGarnishTabs();
      });
    },
    getChangedData() {
      if (!this.originalDelegations) return [];
      
      const original = JSON.parse(this.originalDelegations);
      const changes = [];
      
      // Compare each delegation with its original state
      this.delegations.forEach((delegation, delegationIndex) => {
        // Skip the first few columns that are not editable (ID, name, etc.)
        for (let columnIndex = 4; columnIndex < this.datatableHeaders.length; columnIndex++) {
          const headerValue = this.datatableHeaders[columnIndex].value;
          
          if (
            original[delegationIndex] && 
            (original[delegationIndex][headerValue].active !== delegation[headerValue].active ||
             original[delegationIndex][headerValue].price !== delegation[headerValue].price)
          ) {
            changes.push({
              row_id: delegation[0],  // The ID is at index 0
              column: parseInt(headerValue) - 2,  // Based on saveDelegation's logic
              extraPrice: delegation[headerValue].price,
              value: delegation[headerValue].active
            });
          }
        }
      });
      return changes;
    },
    bulkSave() {
      if (!this.checkForChanges) return;

      this.loading = true;

      this.axios.post('datatables/erp/updateItemGarnishDataArray/', {
        editWareID: this.itemID,
        garnish: this.getChangedData(),
      }).then((res) => {
        if (!res.data.success) {
          Events.$emit("showSnackbar", {
            message: "Daten konnten nicht gespeichert werden",
            color: "error"
          });
        }else {
          this.loadData();
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: "Daten konnten nicht gespeichert werden",
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    clearActive() {
      this.$swal({
        title: this.$t('generic.lang_warning'),
        text: this.$t('erp.lang_clearActiveExtrasWarning'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_ok'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;
          this.axios.post('datatables/erp/bulkDisableGarnishItem/', {
            itemId: this.itemID
          }).then((res) => {
            if (res.data.success) {
              this.loadData();
              Events.$emit("showSnackbar", {
                message: "Changes updated",
                color: "success"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg || res.data.STATUS,
                color: "error"
              });
            }
          }).catch(err => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          }).finally(() => {
            this.loading = false;
          })
        },
        allowOutsideClick: () => false,
      });
    },
    updateDontAskForExtras() {

      this.loadingDontAskForExtras = true
      this.axios.post(ENDPOINTS.ERP.ITEM.DONTASKFOREXTRAS, {
        itemID: this.itemID,
        dontAskForExtras: (this.dontAskForExtras) ? 1 : 0,
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: "Changes updated",
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.STATUS,
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingDontAskForExtras = false;
      })
    },
    handleActivateAll({ garnishTab }) {
      // Simply set all items in this column to active
      this.delegations.forEach(delegation => {
        if (!delegation[garnishTab.value].active) {
          delegation[garnishTab.value].active = true;
        }
      });
    },    
    handleDeactivateAll({ garnishTab }) {
      // Simply set all items in this column to inactive
      this.delegations.forEach(delegation => {
        if (delegation[garnishTab.value].active) {
          delegation[garnishTab.value].active = false;
        }
      });
    },
    // New methods for the external menu
    openEditMenu(delegation, headerValue, event) {
      this.saveEdit();
      // Store references to what we're editing
      this.editingDelegation = delegation;
      this.editingHeaderValue = headerValue;
      this.targetId = event.target.id;
      // Set initial values
      this.editActive = delegation[headerValue].active;
      this.editPrice = delegation[headerValue].price;
      
      // Position the menu near the clicked element
      this.menuPosition.x = event.clientX;
      this.menuPosition.y = event.clientY;
      // Open the menu
      this.menuOpen = true;
    },
    
    saveEdit() {
      this.targetId = null;
      if (this.editingDelegation && this.editingHeaderValue) {
        // Apply the edited values to the actual delegation object
        this.editingDelegation[this.editingHeaderValue].active = this.editActive;
        this.editingDelegation[this.editingHeaderValue].price = this.editPrice;
        // Close the menu
        this.menuOpen = false;
      }
    },
    
    cancelEdit() {
      this.editingDelegation = null;
      this.editingHeaderValue = null;
      this.targetId = null;
      this.editActive = false;
      this.editPrice = 0;
      this.menuOpen = false;
    },
  }
}
</script>

<style scoped>
.clickable-cell {
  cursor: pointer;
}
.clickable-cell:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>